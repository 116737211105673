// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  /*--ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;*/
  --ion-color-primary: var(--app-main);
  --ion-color-primary-rgb: var(--app-main-rgb);
  --ion-color-primary-contrast: var(--app-base);
  --ion-color-primary-contrast-rgb: var(--app-base-rgb);
  --ion-color-primary-shade: #2885d5;
  --ion-color-primary-tint: #43a1f3;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


// Ionic's dark theme

/*
@media (prefers-color-scheme: dark) {
  !*
   * Dark Colors
   * -------------------------------------------
   *!

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  !*
   * iOS Dark Theme
   * -------------------------------------------
   *!

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  !*
   * Material Design Dark Theme
   * -------------------------------------------
   *!

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/




// -------------------------------------------------
// our colors:


body {


  // Palette (initially copied from telegram-web)

  // unused keywords: accent, highlight, shade, tint


  --page_px: 20px;

  // main

  --app-main-darker: #252759;
  --app-main-darker-rgb: 37, 39, 89;
  --app-m08-main-darker: var(--app-main-darker);
  --app-m08-main-darker-rgb: var(--app-main-darker-rgb);

  --app-main-strong: #0455bf; // ex: --app-blue-dark
  --app-main-strong-rgb: 4, 85, 191;
  --app-m12-main-strong: var(--app-main-strong);
  --app-m12-main-strong-rgb: var(--app-main-strong-rgb);


  --app-main: #2e97f2; // Bright blue
  --app-main-rgb: 46, 151, 242;
  --app-m16-main: var(--app-main);
  --app-m16-main-rgb: var(--app-main-rgb);


  //--app-main-soft: #5ba8f3;
  //--app-main-soft-rgb: 91, 168, 243;
  //--app-m20-main-soft: var(--app-main-soft);
  //--app-m20-main-soft-rgb: var(--app-main-soft-rgb);

  --app-main-softer: #aed3f2;
  --app-main-softer-rgb: 174, 211, 242;
  --app-m24-main-softer: var(--app-main-softer);
  --app-m24-main-softer-rgb: var(--app-main-softer-rgb);

  --app-main-hover: #dbeafd;



  // alternative

  //--app-alt





  // contrast

  --app-contr: #f25c05;
  --app-contr-rgb: 242, 92, 5;


  // system

  --app-danger: #eb445a; // Ionic
  --app-danger-rgb: 235, 68, 90;

  --app-succ: #29c366; // Ionic (shade)
  --app-succ-rgb: 41, 195, 102;

  --app-warn: #f9c307;
  --app-warn-rgb: 249, 195, 7;

  --app-violet: #6c7acb;
  --app-violet-rgb: 108, 122, 203;





  // "ordinary color" scale

  --app-cover: #000000;
  --app-cover-rgb: 0, 0, 0;
  --app-o04-cover: var(--app-cover);
  --app-o04-cover-rgb: var(--app-cover-rgb);


  //--app-dark: #222428; // Ionic
  //--app-dark-rgb: 34, 36, 40;
  //--app-o08-dark: var(--app-dark);
  //--app-o08-dark-rgb: var(--app-dark-rgb);


  --app-mid-dark: #374957;
  --app-mid-dark-rgb: 55, 73, 87;
  --app-o12-mid-dark: var(--app-mid-dark);
  --app-o12-mid-dark-rgb: var(--app-mid-dark-rgb);

  --app-mid: #5f7d95; // Mostly desaturated dark blue
  --app-mid-rgb: 95, 125, 149;
  --app-o16-mid: var(--app-mid);
  --app-o16-mid-rgb: var(--app-mid-rgb);

  --app-mid-light: #959393; // Dark grayish red
  --app-mid-light-rgb: 149, 147, 147;
  --app-o20-mid-light: var(--app-mid-light);
  --app-o20-mid-light-rgb: var(--app-mid-light-rgb);

  --app-mid-lighter: #c9c3c3;
  --app-mid-lighter-rgb: 201, 195, 195;
  --app-o24-mid-lighter: var(--app-mid-lighter);
  --app-o24-mid-lighter-rgb: var(--app-mid-lighter-rgb);

  --app-mid-lighter-2: #d3cdcd;
  --app-mid-lighter-2-rgb: 211, 205, 205;
  --app-o28-mid-lighter-2: var(--app-mid-lighter-2);
  --app-o28-mid-lighter-2-rgb: var(--app-mid-lighter-2-rgb);


  --app-light: #f4f6f8;
  --app-light-rgb: 244, 246, 248;
  --app-o32-light: var(--app-light);
  --app-o32-light-rgb: var(--app-light-rgb);


  --app-base: #ffffff;
  --app-base-rgb: 255, 255, 255;
  --app-o36-base: var(--app-base);
  --app-o36-base-rgb: var(--app-base-rgb);








  // CSS Variables

  // legend
  // base | contrast | <<shade & tint>>? (the color(s) they're based on, ONLY if different from the base one)



  // base | contr
  --ion-color-base_contr: var(--app-base);
  --ion-color-base_contr-rgb: var(--app-base-rgb);
  --ion-color-base_contr-contrast: var(--app-contr);
  --ion-color-base_contr-contrast-rgb: var(--app-contr-rgb);
  --ion-color-base_contr-shade: #e0e0e0;
  --ion-color-base_contr-tint: var(--app-base);


  // base | danger
  --ion-color-base_danger: var(--app-base);
  --ion-color-base_danger-rgb: var(--app-base-rgb);
  --ion-color-base_danger-contrast: var(--app-danger);
  --ion-color-base_danger-contrast-rgb: var(--app-danger-rgb);
  --ion-color-base_danger-shade: #e0e0e0;
  --ion-color-base_danger-tint: var(--app-base);


  // base | main
  --ion-color-base_main: var(--app-base);
  --ion-color-base_main-rgb: var(--app-base-rgb);
  --ion-color-base_main-contrast: var(--app-main);
  --ion-color-base_main-contrast-rgb: var(--app-main-rgb);
  --ion-color-base_main-shade: #e0e0e0;
  --ion-color-base_main-tint: var(--app-base);


  // base | main-darker
  --ion-color-base_main-darker: var(--app-base);
  --ion-color-base_main-darker-rgb: var(--app-base-rgb);
  --ion-color-base_main-darker-contrast: var(--app-main-darker);
  --ion-color-base_main-darker-contrast-rgb: var(--app-main-darker-rgb);
  --ion-color-base_main-darker-shade: #e0e0e0;
  --ion-color-base_main-darker-tint: var(--app-base);


  // base | main-softer
  --ion-color-base_main-softer: var(--app-base);
  --ion-color-base_main-softer-rgb: var(--app-base-rgb);
  --ion-color-base_main-softer-contrast: var(--app-main-softer);
  --ion-color-base_main-softer-contrast-rgb: var(--app-main-softer-rgb);
  --ion-color-base_main-softer-shade: #e0e0e0;
  --ion-color-base_main-softer-tint: var(--app-base);


  // base | main-strong
  --ion-color-base_main-strong: var(--app-base);
  --ion-color-base_main-strong-rgb: var(--app-base-rgb);
  --ion-color-base_main-strong-contrast: var(--app-main-strong);
  --ion-color-base_main-strong-contrast-rgb: var(--app-main-strong-rgb);
  --ion-color-base_main-strong-shade: #e0e0e0;
  --ion-color-base_main-strong-tint: var(--app-base);


  // base | mid
  --ion-color-base_mid: var(--app-base);
  --ion-color-base_mid-rgb: var(--app-base-rgb);
  --ion-color-base_mid-contrast: var(--app-mid);
  --ion-color-base_mid-contrast-rgb: var(--app-mid-rgb);
  --ion-color-base_mid-shade: #e0e0e0;
  --ion-color-base_mid-tint: var(--app-base);


  // base | mid-dark
  --ion-color-base_mid-dark: var(--app-base);
  --ion-color-base_mid-dark-rgb: var(--app-base);
  --ion-color-base_mid-dark-contrast: var(--app-mid-dark);
  --ion-color-base_mid-dark-contrast-rgb: var(--app-mid-dark-rgb);
  --ion-color-base_mid-dark-shade: #e0e0e0;
  --ion-color-base_mid-dark-tint: var(--app-base);


  // base | mid-light
  --ion-color-base_mid-light: var(--app-base);
  --ion-color-base_mid-light-rgb: var(--app-base-rgb);
  --ion-color-base_mid-light-contrast: var(--app-mid-light);
  --ion-color-base_mid-light-contrast-rgb: var(--app-mid-light-rgb);
  --ion-color-base_mid-light-shade: #e0e0e0;
  --ion-color-base_mid-light-tint: var(--app-base);


  // base | mid-lighter
  --ion-color-base_mid-lighter: var(--app-base);
  --ion-color-base_mid-lighter-rgb: var(--app-base-rgb);
  --ion-color-base_mid-lighter-contrast: var(--app-lighter);
  --ion-color-base_mid-lighter-contrast-rgb: var(--app-lighter-rgb);
  --ion-color-base_mid-lighter-shade: #e0e0e0;
  --ion-color-base_mid-lighter-tint: var(--app-base);


  // base | mid-lighter-2
  --ion-color-base_mid-lighter-2: var(--app-base);
  --ion-color-base_mid-lighter-2-rgb: var(--app-base-rgb);
  --ion-color-base_mid-lighter-2-contrast: var(--app-mid-lighter-2);
  --ion-color-base_mid-lighter-2-contrast-rgb: var(--app-mid-lighter-2-rgb);
  --ion-color-base_mid-lighter-2-shade: #e0e0e0;
  --ion-color-base_mid-lighter-2-tint: var(--app-base);


  // base | succ
  --ion-color-base_succ: var(--app-base);
  --ion-color-base_succ-rgb: var(--app-base-rgb);
  --ion-color-base_succ-contrast: var(--app-succ);
  --ion-color-base_succ-contrast-rgb: var(--app-succ-rgb);
  --ion-color-base_succ-shade: #e0e0e0;
  --ion-color-base_succ-tint: var(--app-base);


  // contr | base
  --ion-color-contr_base: var(--app-contr);
  --ion-color-contr_base-rgb: var(--app-contr-rgb);
  --ion-color-contr_base-contrast: var(--app-base);
  --ion-color-contr_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-contr_base-shade: #d55104;
  --ion-color-contr_base-tint: #f36c1e;


  // danger | base
  --ion-color-danger_base: var(--app-danger);
  --ion-color-danger_base-rgb: var(--app-danger-rgb);
  --ion-color-danger_base-contrast: var(--app-base);
  --ion-color-danger_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-danger_base-shade: #cf3c4f;
  --ion-color-danger_base-tint: #ed576b;


  // light | mid
  --ion-color-light_mid: var(--app-light);
  --ion-color-light_mid-rgb: var(--app-light-rgb);
  --ion-color-light_mid-contrast: var(--app-mid);
  --ion-color-light_mid-contrast-rgb: var(--app-mid-rgb);
  --ion-color-light_mid-shade: #d7d8da;
  --ion-color-light_mid-tint: #f5f7f9;


  // main | base
  --ion-color-main_base: var(--app-main);
  --ion-color-main_base-rgb: var(--app-main-rgb);
  --ion-color-main_base-contrast: var(--app-base);
  --ion-color-main_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-main_base-shade: #2885d5;
  --ion-color-main_base-tint: #43a1f3;


  // main-darker | base
  --ion-color-main-darker_base: var(--app-main-darker);
  --ion-color-main-darker_base-rgb: var(--app-main-darker-rgb);
  --ion-color-main-darker_base-contrast: var(--app-base);
  --ion-color-main-darker_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-main-darker_base-shade: #21224e;
  --ion-color-main-darker_base-tint: #3b3d6a;


  // main-softer | base
  --ion-color-main-softer_base: var(--app-main-softer);
  --ion-color-main-softer_base-rgb: var(--app-main-softer-rgb);
  --ion-color-main-softer_base-contrast: var(--app-base);
  --ion-color-main-softer_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-main-softer_base-shade: #99bad5;
  --ion-color-main-softer_base-tint: #b6d7f3;


  // main-strong | base
  --ion-color-main-strong_base: var(--app-main-strong);
  --ion-color-main-strong_base-rgb: var(--app-main-strong-rgb);
  --ion-color-main-strong_base-contrast: var(--app-base);
  --ion-color-main-strong_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-main-strong_base-shade: #044ba8;
  --ion-color-main-strong_base-tint: #1d66c5;


  // mid | base
  --ion-color-mid_base: var(--app-mid);
  --ion-color-mid_base-rgb: var(--app-mid-rgb);
  --ion-color-mid_base-contrast: var(--app-base);
  --ion-color-mid_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-mid_base-shade: #546e83;
  --ion-color-mid_base-tint: #6f8aa0;


  // mid | light
  --ion-color-mid_light: var(--app-mid);
  --ion-color-mid_light-rgb: var(--app-mid-rgb);
  --ion-color-mid_light-contrast: var(--app-light);
  --ion-color-mid_light-contrast-rgb: var(--app-light-rgb);
  --ion-color-mid_light-shade: #546e83;
  --ion-color-mid_light-tint: #6f8aa0;


  // mid-dark | base
  --ion-color-mid-dark_base: var(--app-mid-dark);
  --ion-color-mid-dark_base-rgb: var(--app-mid-dark-rgb);
  --ion-color-mid-dark_base-contrast: var(--app-base);
  --ion-color-mid-dark_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-mid-dark_base-shade: #30404d;
  --ion-color-mid-dark_base-tint: #4b5b68;


  // mid-light | base
  --ion-color-mid-light_base: var(--app-mid-light);
  --ion-color-mid-light_base-rgb: var(--app-mid-light-rgb);
  --ion-color-mid-light_base-contrast: var(--app-base);
  --ion-color-mid-light_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-mid-light_base-shade: #838181;
  --ion-color-mid-light_base-tint: #a09e9e;


  // mid-lighter | base
  --ion-color-mid-lighter_base: var(--app-mid-lighter);
  --ion-color-mid-lighter_base-rgb: var(--app-mid-lighter);
  --ion-color-mid-lighter_base-contrast: var(--app-base);
  --ion-color-mid-lighter_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-mid-lighter_base-shade: #b1acac;
  --ion-color-mid-lighter_base-tint: #cec9c9;


  // mid-lighter-2 | base
  --ion-color-mid-lighter-2_base: var(--app-mid-lighter-2);
  --ion-color-mid-lighter-2_base-rgb: var(--app-mid-lighter-2-rgb);
  --ion-color-mid-lighter-2_base-contrast: var(--app-base);
  --ion-color-mid-lighter-2_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-mid-lighter-2_base-shade: #bab4b4;
  --ion-color-mid-lighter-2_base-tint: #d7d2d2;


  // succ | base
  --ion-color-succ_base: var(--app-succ);
  --ion-color-succ_base-rgb: var(--app-succ-rgb);
  --ion-color-succ_base-contrast: var(--app-base);
  --ion-color-succ_base-contrast-rgb: var(--app-base-rgb);
  --ion-color-succ_base-shade: #24ac5a;
  --ion-color-succ_base-tint: #3ec975;





}






.ion-color-base_contr {
  --ion-color-base: var(--ion-color-base_contr);
  --ion-color-base-rgb: var(--ion-color-base_contr-rgb);
  --ion-color-contrast: var(--ion-color-base_contr-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_contr-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_contr-shade);
  --ion-color-tint: var(--ion-color-base_contr-tint);
}

.ion-color-base_danger {
  --ion-color-base: var(--ion-color-base_danger);
  --ion-color-base-rgb: var(--ion-color-base_danger-rgb);
  --ion-color-contrast: var(--ion-color-base_danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_danger-shade);
  --ion-color-tint: var(--ion-color-base_danger-tint);
}

.ion-color-base_main {
  --ion-color-base: var(--ion-color-base_main);
  --ion-color-base-rgb: var(--ion-color-base_main-rgb);
  --ion-color-contrast: var(--ion-color-base_main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_main-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_main-shade);
  --ion-color-tint: var(--ion-color-base_main-tint);
}

.ion-color-base_main-darker {
  --ion-color-base: var(--ion-color-base_main-darker);
  --ion-color-base-rgb: var(--ion-color-base_main-darker-rgb);
  --ion-color-contrast: var(--ion-color-base_main-darker-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_main-darker-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_main-darker-shade);
  --ion-color-tint: var(--ion-color-base_main-darker-tint);
}

.ion-color-base_main-softer {
  --ion-color-base: var(--ion-color-base_main-softer);
  --ion-color-base-rgb: var(--ion-color-base_main-softer-rgb);
  --ion-color-contrast: var(--ion-color-base_main-softer-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_main-softer-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_main-softer-shade);
  --ion-color-tint: var(--ion-color-base_main-softer-tint);
}

.ion-color-base_main-strong {
  --ion-color-base: var(--ion-color-base_main-strong);
  --ion-color-base-rgb: var(--ion-color-base_main-strong-rgb);
  --ion-color-contrast: var(--ion-color-base_main-strong-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_main-strong-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_main-strong-shade);
  --ion-color-tint: var(--ion-color-base_main-strong-tint);
}

.ion-color-base_mid {
  --ion-color-base: var(--ion-color-base_mid);
  --ion-color-base-rgb: var(--ion-color-base_mid-rgb);
  --ion-color-contrast: var(--ion-color-base_mid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_mid-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_mid-shade);
  --ion-color-tint: var(--ion-color-base_mid-tint);
}

.ion-color-base_mid-dark {
  --ion-color-base: var(--ion-color-base_mid-dark);
  --ion-color-base-rgb: var(--ion-color-base_mid-dark-rgb);
  --ion-color-contrast: var(--ion-color-base_mid-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_mid-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_mid-dark-shade);
  --ion-color-tint: var(--ion-color-base_mid-dark-tint);
}

.ion-color-base_mid-light {
  --ion-color-base: var(--ion-color-base_mid-light);
  --ion-color-base-rgb: var(--ion-color-base_mid-light-rgb);
  --ion-color-contrast: var(--ion-color-base_mid-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_mid-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_mid-light-shade);
  --ion-color-tint: var(--ion-color-base_mid-light-tint);
}

.ion-color-base_mid-lighter {
  --ion-color-base: var(--ion-color-base_mid-lighter);
  --ion-color-base-rgb: var(--ion-color-base_mid-lighter-rgb);
  --ion-color-contrast: var(--ion-color-base_mid-lighter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_mid-lighter-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_mid-lighter-shade);
  --ion-color-tint: var(--ion-color-base_mid-lighter-tint);
}

.ion-color-base_mid-lighter-2 {
  --ion-color-base: var(--ion-color-base_mid-lighter-2);
  --ion-color-base-rgb: var(--ion-color-base_mid-lighter-2-rgb);
  --ion-color-contrast: var(--ion-color-base_mid-lighter-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_mid-lighter-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_mid-lighter-2-shade);
  --ion-color-tint: var(--ion-color-base_mid-lighter-2-tint);
}

.ion-color-base_succ {
  --ion-color-base: var(--ion-color-base_succ);
  --ion-color-base-rgb: var(--ion-color-base_succ-rgb);
  --ion-color-contrast: var(--ion-color-base_succ-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base_succ-contrast-rgb);
  --ion-color-shade: var(--ion-color-base_succ-shade);
  --ion-color-tint: var(--ion-color-base_succ-tint);
}

.ion-color-contr_base {
  --ion-color-base: var(--ion-color-contr_base);
  --ion-color-base-rgb: var(--ion-color-contr_base-rgb);
  --ion-color-contrast: var(--ion-color-contr_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-contr_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-contr_base-shade);
  --ion-color-tint: var(--ion-color-contr_base-tint);
}

.ion-color-danger_base {
  --ion-color-base: var(--ion-color-danger_base);
  --ion-color-base-rgb: var(--ion-color-danger_base-rgb);
  --ion-color-contrast: var(--ion-color-danger_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger_base-shade);
  --ion-color-tint: var(--ion-color-danger_base-tint);
}

.ion-color-light_mid {
  --ion-color-base: var(--ion-color-light_mid);
  --ion-color-base-rgb: var(--ion-color-light_mid-rgb);
  --ion-color-contrast: var(--ion-color-light_mid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light_mid-contrast-rgb);
  --ion-color-shade: var(--ion-color-light_mid-shade);
  --ion-color-tint: var(--ion-color-light_mid-tint);
}

.ion-color-main_base {
  --ion-color-base: var(--ion-color-main_base);
  --ion-color-base-rgb: var(--ion-color-main_base-rgb);
  --ion-color-contrast: var(--ion-color-main_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-main_base-shade);
  --ion-color-tint: var(--ion-color-main_base-tint);
}

.ion-color-main-darker_base {
  --ion-color-base: var(--ion-color-main-darker_base);
  --ion-color-base-rgb: var(--ion-color-main-darker_base-rgb);
  --ion-color-contrast: var(--ion-color-main-darker_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-darker_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-darker_base-shade);
  --ion-color-tint: var(--ion-color-main-darker_base-tint);
}

.ion-color-main-softer_base {
  --ion-color-base: var(--ion-color-main-softer_base);
  --ion-color-base-rgb: var(--ion-color-main-softer_base-rgb);
  --ion-color-contrast: var(--ion-color-main-softer_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-softer_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-softer_base-shade);
  --ion-color-tint: var(--ion-color-main-softer_base-tint);
}

.ion-color-main-strong_base {
  --ion-color-base: var(--ion-color-main-strong_base);
  --ion-color-base-rgb: var(--ion-color-main-strong_base-rgb);
  --ion-color-contrast: var(--ion-color-main-strong_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-strong_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-strong_base-shade);
  --ion-color-tint: var(--ion-color-main-strong_base-tint);
}

.ion-color-mid_base {
  --ion-color-base: var(--ion-color-mid_base);
  --ion-color-base-rgb: var(--ion-color-mid_base-rgb);
  --ion-color-contrast: var(--ion-color-mid_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid_base-shade);
  --ion-color-tint: var(--ion-color-mid_base-tint);
}

.ion-color-mid_light {
  --ion-color-base: var(--ion-color-mid_light);
  --ion-color-base-rgb: var(--ion-color-mid_light-rgb);
  --ion-color-contrast: var(--ion-color-mid_light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid_light-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid_light-shade);
  --ion-color-tint: var(--ion-color-mid_light-tint);
}

.ion-color-mid-dark_base {
  --ion-color-base: var(--ion-color-mid-dark_base);
  --ion-color-base-rgb: var(--ion-color-mid-dark_base-rgb);
  --ion-color-contrast: var(--ion-color-mid-dark_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-dark_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-dark_base-shade);
  --ion-color-tint: var(--ion-color-mid-dark_base-tint);
}

.ion-color-mid-light_base {
  --ion-color-base: var(--ion-color-mid-light_base);
  --ion-color-base-rgb: var(--ion-color-mid-light_base-rgb);
  --ion-color-contrast: var(--ion-color-mid-light_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-light_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-light_base-shade);
  --ion-color-tint: var(--ion-color-mid-light_base-tint);
}

.ion-color-mid-lighter_base {
  --ion-color-base: var(--ion-color-mid-lighter_base);
  --ion-color-base-rgb: var(--ion-color-mid-lighter_base-rgb);
  --ion-color-contrast: var(--ion-color-mid-lighter_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-lighter_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-lighter_base-shade);
  --ion-color-tint: var(--ion-color-mid-lighter_base-tint);
}

.ion-color-mid-lighter-2_base {
  --ion-color-base: var(--ion-color-mid-lighter-2_base);
  --ion-color-base-rgb: var(--ion-color-mid-lighter-2_base-rgb);
  --ion-color-contrast: var(--ion-color-mid-lighter-2_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mid-lighter-2_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-mid-lighter-2_base-shade);
  --ion-color-tint: var(--ion-color-mid-lighter-2_base-tint);
}

.ion-color-succ_base {
  --ion-color-base: var(--ion-color-succ_base);
  --ion-color-base-rgb: var(--ion-color-succ_base-rgb);
  --ion-color-contrast: var(--ion-color-succ_base-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succ_base-contrast-rgb);
  --ion-color-shade: var(--ion-color-succ_base-shade);
  --ion-color-tint: var(--ion-color-succ_base-tint);
}























































