/* The popup bubble styling. */
.map-poi-popup-bubble {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.map-poi-popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 55px;
  left: 0;
}

/* This element draws the tip. */
.map-poi-popup-bubble-anchor::after {
  display: none;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.map-poi-popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}
