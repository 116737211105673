/* ------------------- */
/* Custom properties   */
/* ------------------- */

/* classe da aggiungere se si vuole utilizzare il Design system */
.ds {
  //font
  --font-header: 1.06rem;
  --font-title: 1.375rem;
  --font-default: 1rem;
  --font-secondary: 0.875rem;
  --font-label: 0.625rem;
  --font-big: 2rem;
  //weight
  --weight-bigger: 600;
  --weight-normal: 500;
  --weight-small: 300;

  //font-color
  --color-primary: var(--app-mid-dark);
  --color-secondary: var(--app-mid-light);
  --color-background: var(--app-light);
  --color-base: var(--app-base);

  //border
  --border-radius: 0.25rem;
  --border-radius-bigger: 0.625rem;
  --border-divider: 0.7px solid var(--app-mid-lighter-2);
  --border: 1px solid var(--app-mid-lighter-2);

  //badge color
  --badge-paid-color: #9EEDB6;
  --badge-unpaid-color: #F88484;
  --badge-inprogress-color: #F8F881;

  //padding
  --padding: 1.25rem 1.25rem 3.125rem;

  //margin
  --margin-bottom: 0.5rem;
  --margin-bottom-default: 1.5rem;

  //box shadow
  --box-shadow: 0 8px 16px rgba(var(--app-cover-rgb), .15);


  //spacing
  --small-spacing: 0.25rem;
  --normal-spacing: 0.50rem;
  --large-spacing: 0.75rem;

  //icon sizes
  --icon-size-large: 1.5rem;


  //general
  --size-large: 1.5rem;

  //shadow

}


/* make form elements easier to work with */
input,
button,
textarea,
select {
  font: inherit;
}

/* ------------------- */
/* Utility classes     */
/* ------------------- */

.d-block {
  display: block;
}

.grid {
  display: grid;
}

.flex {
  display: flex;

  &.center {
    justify-content: center !important;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }

  &.start {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.end {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
    align-content: center;
  }

  &.g1 {
    column-gap: 1rem !important;
  }

  &.g1p {
    column-gap: 10% !important;
  }

  &.g2 {
    column-gap: 2rem !important;
  }

  &.g25 {
    column-gap: 2rem !important;
  }

  &.g05 {
    column-gap: 0.5rem !important;
  }

  &.g025 {
    column-gap: 0.25rem !important;
  }


  &.gap1 {
    gap: 1rem;
  }

  &.gap05 {
    gap: 0.5rem;
  }

  &.gap025 {
    gap: 0.25rem;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  .grow {
    flex-grow: 1;
  }
}

.card-wrap {

  .card {
    border-radius: var(--border-radius);
    height: 100%;
    background-color: var(--app-base);
    box-shadow: var(--box-shadow);

    .head {
      border-bottom: var(--border-divider);
      padding: 0.5rem 0.75rem;
      color: var(--app-mid-dark);
      font-weight: var(--weight-bigger);
      display: flex;
      align-items: center;

      .card-head-icon {
        margin-right: 10px;
        font-size: 18px;
      }

    }


  }
}

.table {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: var(--font-secondary);
  color: var(--color-primary);
  font-weight: var(--weight-normal);

  .table-header {
    display: grid;
    grid-template-columns: 0.7fr 0.55fr 0.5fr 1fr 1fr;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    font-size: var(--font-secondary);
    color: var(--color-secondary);
    padding: 0.5rem 0.1rem 0.5rem 0.1rem;
    border-bottom: var(--border-divider);

    span {
      flex: 1;
    }
  }

  .table-content {
    .table-row {
      display: grid;
      grid-template-columns: 0.7fr 0.55fr 0.5fr 1fr 1fr;
      justify-content: space-between;
      align-items: center;
      align-content: flex-start;
      padding: 1rem 0 1rem 0;
      border-bottom: var(--border-divider);

      .invoice-status {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .details {
          display: flex;
          align-items: center;
          align-content: center;
        }
      }

      .payment-method {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: var(--small-spacing);

        .label {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: var(--small-spacing);
        }
      }

      .type {

      }
    }
  }

}

/*Margin*/
.mb05 {
  margin-bottom: 0.5rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.mb2 {
  margin-bottom: 2rem !important;
}

.mt05 {
  margin-top: 0.5rem !important;
}

.mt1 {
  margin-top: 1rem !important;
}

.mt2 {
  margin-top: 2rem !important;
}

.mt3 {
  margin-top: 3rem !important;
}


.mr1 {
  margin-right:11px !important;
}

/* pointer */
.pointer {
  cursor: pointer;
}

/* spacing */
.spacing-small {
  column-gap: var(--small-spacing);
}

.spacing-normal {
  column-gap: var(--normal-spacing);
}

.spacing-large {
  column-gap: var(--large-spacing);
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w30 {
  width: 30%;
}

.w20 {
  width: 20%;
}

.w10 {
  width: 10%;
}



.h100 {
  height: 100%;
}

.h90 {
  height: 90%;
}

.h80 {
  height: 80%;
}

.h70 {
  height: 70%;
}

.h60 {
  height: 60%;
}

.h50 {
  height: 50%;
}

.h40 {
  height: 40%;
}

.h30 {
  height: 30%;
}

.h20 {
  height: 20%;
}

.h10 {
  height: 10%;
}

/* Modal */
.modal-header {
  padding: 15px 20px;
  background-color: var(--app-main-darker);

  .head-elem {
    &.he-title {
      margin-bottom: 20px;

      &.mb-0 {
        margin-bottom: 0;
      }
    }
  }

  &.p-20 {
    padding: 20px 20px;
  }
}

.modal-footer {
  border-top: 1px solid var(--app-mid-lighter-2);
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;

  .foot-btn {
    margin: 0;
    font-weight: 600;

    // .btn-icon {}

    &.mw-120 {
      min-width: 120px;
    }
  }

  &::before {
    content: normal;
  }
}

/*Font size*/
.fs-header {
  font-size: var(--font-header);
}

.fs-default {
  font-size: var(--font-default);
}

.fs-secondary {
  font-size: var(--font-secondary);
}

.fs-label {
  font-size: var(--font-label);
}

.upper {
  text-transform: uppercase;
}

.no-wrap{
  white-space: nowrap;
}

/*Font weight*/
.fw-big {
  font-weight: var(--weight-bigger)
}

.fw-normal {
  font-weight: var(--weight-normal)
}

.fw-small {
  font-weight: var(--weight-small)
}

.title{
  font-size : var(--font-title);
  color: var(--app-mid-dark)
}

.subtitle{
  font-size: var(--font-header);
  font-weight: 300;
}



/* Padding */
.p-select {
  padding: 0 6rem 0 6rem;
}

.p05 {
  padding: 0.5rem !important;
}

.p1 {
  padding: 1rem !important;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.pr05 {
  padding-right: 0.5rem;
}

.lower {
  text-transform: lowercase;
}

/* Width */
.w-mc {
  width: max-content;
}

/* font-color */
.fc-primary{
  color: var(--color-primary)
}

/* Icon */

.icon-big {
  font-size: 20px;
}

/* Card scelta multipla */
.multiple-choice-cards {
  display: grid;
  align-content: flex-start;
  justify-content: center;
  grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
  gap: 30px 35px;
  margin: 30px auto;

  .card {
    border-radius: var(--border-radius-bigger);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    padding: 4rem 0rem 4rem 0rem;
    font-size: var(--font-header);
    font-weight: var(--weight-normal);
    color: var(--color-primary);

    .big {
      font-size: 4rem;
    }

    .card-lbl {
      margin-top: 1rem;
    }
  }
}

.modal_alert:last-of-type {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important
}

//payment
.balin-payment {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.balin-payment--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.balin-payment--invalid {
  border-color: #fa755a;
}

.balin-payment--webkit-autofill {
  background-color: #fefde5 !important;
}

.select-payment-method {
  border: 1px solid var(--color-secondary);
  border-radius: 10px;
}

.badge-status-invoice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  --color: var(--color-primary);
  column-gap: var(--small-spacing);
  width: max-content;
}

.field-input-payment {
  max-width: calc(400px * .75);;
  margin-bottom: 20px;

  .fi-input {
    border: var(--border);
    border-radius: var(--border-radius);
    --padding-end: 8px;
    padding: 12px;
    font-size: var(--font-secondary);

    &.border-danger{
      border-color: var(--app-danger);
    }
  }

  &.field-input-payment--select {
    position: relative;
    cursor: pointer;

    .fi-input {
      pointer-events: none;
      --padding-end: 43px;
    }

    .sel-icon {
      position: absolute;
      top: 50%;
      right: 15px;

      transform: translateY(-50%);
    }
  }

  .input-error{
    position: absolute;
    color: var(--app-danger);
    padding: 2px;
  }

}

.small {
  font-size: var(--font-secondary) !important;
  color: var(--color-secondary) !important;
  &.--8{
    font-size: var(--font-label) !important;
  }
}

select-payment-method{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
}

.disabled{
  pointer-events: none;
}

.line-through{
  text-decoration: line-through;
}

.input-lbl{
  margin-bottom: 5px;
  font-size: 14px;

  &.text-muted{
    color: var(--app-mid-light);
  }
  &.input-lbl--mid-dark {
    color: var(--app-mid-dark);
  }
}

.field-input {

  ion-input, ion-textarea{
    &.is-invalid{
      margin-bottom: 5px;
      border: 0.07rem solid var(--app-danger) !important;
    }
    &.is-valid{
      margin-bottom: 5px;
      border: 0.06rem solid var(--app-succ) !important;
    }
  }
  .fi-input {
    border-radius: 5px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 12px;
    --padding-end: 12px;
    border: var(--border-divider);
    font-size: 14px;

    &.border-danger{
      border-color: var(--app-danger);
    }
    &.big{
      font-size: 16px;
      font-weight: 400;
      color: #495057;
    }
  }

  &.field-input--select {
    position: relative;
    cursor: pointer;

    .fi-input {
      pointer-events: none;
      --padding-end: 43px;
    }

    .sel-icon {
      position: absolute;
      top: 50%;
      right: 15px;

      transform: translateY(-50%);
    }
  }

  .input-error{
    position: absolute;
    color: var(--app-danger);
    padding: 2px;
  }

}

.input-field-wrap {
  position: relative;

  .input-error {
    position: absolute;
    bottom: -16px;
  }

  // Aggiunta Vigo
  .border-danger{
    border-color: var(--app-danger)!important;
  }
  // Fine aggiunta Vigo
}

//Form validation

.error-validation{
  color: var(--app-danger);
  font-size: 0.625rem;
  p{
    padding:0;
    margin: 0;
  }
}

.-h{
  display: none;
}

// Success animation
.success-check {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before, &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: transparent;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: transparent;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

}

.error-check {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid rgb(175, 76, 76);

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before, &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: rgba(255, 255, 255, 0);
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: rgb(175, 76, 76);
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(45deg);
        animation: icon-line-long 0.75s;
      }

      &.line-long {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.85s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(175, 76, 76, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: rgba(255, 255, 255, 0);
    }
  }


}

.swiper-pagination{
  position: relative;
  bottom: 0;
}

.underline{
  text-decoration: underline;
}

.link{
  color: var(--app-main);
  cursor: pointer;
  text-decoration: underline;
}


// Success animation
.success-check {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before, &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

}


.error-check {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid rgb(175, 76, 76);

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before, &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: rgba(255, 255, 255, 0);
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: rgb(175, 76, 76);
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(45deg);
        animation: icon-line-long 0.75s;
      }

      &.line-long {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.85s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(175, 76, 76, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: rgba(255, 255, 255, 0);
    }
  }
}


.recap-grid {
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  grid-gap: 12px 25px;
  gap: 12px 25px;
  justify-content: center;

  $fz: 17px;
  $fw: 500;

  &.mw-75p {
    margin: 0 auto;
    max-width: 75%;
  }
}

.output-lbl{
  color: var(--app-mid-light);
  font-size: 17px;
  font-weight: 500;
}



.output-val {
  color: var(--app-mid-dark);
  font-size: 17px;
  font-weight: 500;
}


span {
  &.small {
    font-size: var(--font-secondary);
    color: var(--color-secondary);
  }

  &.small-8 {
    font-size: var(--font-label);
    color: var(--color-secondary);
    &.danger{
      color: var(--ion-color-danger);
    }
  }
}
